import { api } from '@/const/axiosApi'

// Helper function to get the current company from localStorage
function getCurrentCompany() {
  return JSON.parse(window.localStorage.getItem('currentCompany')) || null
}

// Helper function to set user roles in localStorage and commit the mutation
function setUserRolesFromEmployers(commit, data) {
  window.localStorage.setItem('userRoles', JSON.stringify(data))
  commit('SET_CURRENT_USER_ROLES', data)
}

export default {
  namespaced: true,
  state: {
    user: null,
    employers: [],
    selectedUserEmployers: [],
    userRoles: JSON.parse(window.localStorage.getItem('userRoles')) || ['ROLE_USER'],
    isFileLoading: false,
  },
  actions: {
    async getUser({ commit, dispatch }) {
      try {
        const { data } = await api.get('/api/v1/user/profile')
        commit('SET_CURRENT_USER', data.data)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async updateUser({ commit, dispatch }, updatedUser) {
      try {
        const { data } = await api.put('/api/v1/user/profile', updatedUser)
        commit('SET_CURRENT_USER', data.data)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async uploadProfileLogo({ commit, dispatch }, payload) {
      try {
        commit('SET_FILE_LOADING', true)
        await api.post('/api/v1/user/profile/upload-avatar', payload.formData)
        await dispatch('getUser')
        await dispatch('logs/displaySuccessAlert', { message: 'Лого завантажено успішно!' }, { root: true })
        commit('SET_FILE_LOADING', false)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async deleteProfileLogo({ commit, dispatch }) {
      try {
        commit('SET_FILE_LOADING', true)
        await api.delete('/api/v1/user/profile/delete-avatar')
        await dispatch('getUser')
        await dispatch('logs/displaySuccessAlert', { message: 'Лого видалено!' }, { root: true })
        commit('SET_FILE_LOADING', false)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async getUserEmployers({ commit, dispatch, rootGetters }) {
      const currentUserId = rootGetters['profile/getCurrentUserId']
      if (currentUserId) {
        try {
          const { data } = await api.get(`/api/v1/user/${currentUserId}/get-employers`)
          commit('SET_CURRENT_USER_EMPLOYERS', data.data)
        } catch (e) {
          dispatch('logs/displayErrorAlert', e.response, { root: true })
        }
      } else console.error(`User Id is ${currentUserId}`)
    },
    async loadSelectedUserEmployers({ commit, dispatch }, payload) {
      try {
        const { data } = await api.get(`/api/v1/user/${payload.userId}/get-employers`)
        commit('SET_SELECTED_USER_EMPLOYERS', data.data)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async setUserRoles({ state, commit }) {
      const currentCompany = getCurrentCompany()
      const userRoles =
        state.employers.length && currentCompany?.id
          ? state.employers.find((employer) => employer.company.id === currentCompany.id)?.position.roles
          : null
      if (userRoles) {
        setUserRolesFromEmployers(commit, userRoles)
      } else {
        setUserRolesFromEmployers(commit, ['ROLE_USER'])
      }
    },
  },
  mutations: {
    SET_CURRENT_USER(state, user) {
      state.user = user
    },
    SET_CURRENT_USER_EMPLOYERS(state, employers) {
      state.employers = employers
    },
    SET_SELECTED_USER_EMPLOYERS(state, selectedUserEmployers) {
      state.selectedUserEmployers = selectedUserEmployers
    },
    SET_CURRENT_USER_ROLES(state, roles) {
      state.userRoles = roles || ['ROLE_USER']
    },
    SET_FILE_LOADING(state, val) {
      state.isFileLoading = val
    },
  },
  getters: {
    getCurrentUserId(state) {
      return state.user?.id || null
    },
    getCurrentUserLocale(state) {
      return state.user?.locale || null
    },
  },
}
