import Vue from 'vue'
import VueRouter from 'vue-router'

import isAuthenticated from '@/helpers/isAuthenticated'
import { ROUTER_LINKS } from '@/const/router-links.enum'
import store from '@/store'
import { getLocalRefreshToken, isTokenExpired } from '@/helpers/getAuthToken'
import AxiosApi from '../services/AxiosApi'
import { ROLE } from '@/const/roles.enum'

Vue.use(VueRouter)

const routes = [
  {
    path: ROUTER_LINKS.HOME,
    name: 'Home',
    component: () => import('../views/Home'),
    meta: {
      requiresAuth: true,
      title: 'Головна',
    },
  },
  {
    path: ROUTER_LINKS.LOGIN,
    name: 'Login',
    component: () => import('../views/Login'),
    meta: {
      requiresAuth: false,
      title: 'Увійти',
    },
  },
  {
    path: ROUTER_LINKS.REGISTRATION,
    name: 'Registration',
    component: () => import('../views/Registration'),
    meta: {
      requiresAuth: false,
      title: 'Зареєструватися',
    },
  },
  {
    path: ROUTER_LINKS.VERIFICATION,
    name: 'Verification',
    component: () => import('../views/Verification'),
    meta: {
      requiresAuth: false,
      title: 'Верифікація',
    },
  },
  {
    path: ROUTER_LINKS.RESTORE_PASSWORD,
    name: 'RestorePassword',
    component: () => import('../views/RestorePassword.vue'),
    meta: {
      requiresAuth: false,
      title: 'Відновити пароль',
    },
  },
  {
    path: '/spa/about',
    name: 'About',
    component: () => import('../views/About.vue'),
    meta: {
      requiresAuth: true,
      title: 'Про нас',
    },
  },
  {
    path: ROUTER_LINKS.PROFILE,
    name: 'Profile',
    component: () => import('../views/Profile'),
    meta: {
      requiresAuth: true,
      title: 'Профіль',
    },
  },
  {
    path: ROUTER_LINKS.ADMIN_MAIN,
    name: 'AdminMain',
    component: () => import('../views/AdminMain'),
    meta: {
      requiresAuth: true,
      title: 'Сторінка адміністратора',
      roles: [ROLE.ADMIN, ROLE.OWNER],
    },
  },
  {
    path: ROUTER_LINKS.ERRORS.ERROR_403,
    name: '403',
    component: () => import('../views/errors/403.vue'),
    meta: {
      title: 'Forbidden',
    },
  },
  {
    path: ROUTER_LINKS.ERRORS.ERROR_404,
    name: '404',
    component: () => import('../views/errors/404.vue'),
    meta: {
      title: 'Not found',
    },
  },
  {
    path: ROUTER_LINKS.ERRORS.ERROR_500,
    name: '500',
    component: () => import('../views/errors/500.vue'),
    meta: {
      title: 'Server error',
    },
  },
  {
    path: ROUTER_LINKS.ERRORS.ERROR_COMPANY_LOCKED,
    name: 'CompanyLocked',
    component: () => import('../views/errors/CompanyLocked.vue'),
    meta: {
      title: 'Locked',
    },
  },
  {
    path: '/company/:id',
    name: 'Company',
    component: () => import('../views/Company.vue'),
    meta: {
      requiresAuth: true,
      title: 'Компанія',
    },
    children: [
      {
        path: ROUTER_LINKS.DASHBOARD + '/:dashboardId',
        name: 'DashboardMain',
        component: () => import('../views/dashboards/DashboardMain'),
        meta: {
          title: 'Головний дашборд',
          // roles: [ROLE.ADMIN, ROLE.OWNER],
        },
      },
      {
        path: ROUTER_LINKS.MONITORING,
        name: 'Monitoring',
        component: () => import('../views/monitoring/Monitoring'),
        meta: {
          title: 'Моніторинг',
          roles: [ROLE.ADMIN, ROLE.OWNER, ROLE.ACCOUNTANT, ROLE.TECHNICIAN, ROLE.SUPPORT],
        },
      },
      {
        path: ROUTER_LINKS.COMPANY_DASHBOARD,
        name: 'CompanyDashboard',
        component: () => import('../views/CompanyDashboard.vue'),
        meta: {
          title: 'Дошка компанії',
        },
      },
      {
        path: ROUTER_LINKS.PAYMENTS,
        name: 'PaymentsList',
        component: () => import('../views/finance/payments/PaymentsList'),
        meta: {
          title: 'Платежі',
        },
      },
      {
        path: ROUTER_LINKS.PAYMENT_CARD + '/:paymentId',
        name: 'PaymentCard',
        component: () => import('../views/finance/payments/PaymentCard'),
        meta: {
          title: 'Платіж',
        },
      },
      {
        path: ROUTER_LINKS.FINANCIAL_STATEMENTS,
        name: 'FinancialStatements',
        component: () => import('../views/finance/financial_statements/FinancialReport'),
        meta: {
          title: 'Фінансові звіти',
        },
      },
      {
        path: ROUTER_LINKS.FINANCIAL_ENCASHMENTS,
        name: 'Encashments',
        component: () => import('../views/finance/encashments/EncashmentsList'),
        meta: {
          title: 'Звіти по інкасаціях',
        },
      },
      {
        path: ROUTER_LINKS.FINANCIAL_ENCASHMENT + '/:encashmentId',
        name: 'EncashmentCard',
        component: () => import('../views/finance/encashments/EncashmentCard'),
        meta: {
          title: 'Інкасація',
        },
      },
      {
        path: ROUTER_LINKS.FINANCIAL_ENCASHMENT,
        name: 'EncashmentCardCreate',
        component: () => import('../views/finance/encashments/EncashmentCard'),
        meta: {
          title: 'Створення інкасації',
        },
      },
      {
        path: ROUTER_LINKS.CLIENTS,
        name: 'Clients',
        component: () => import('../views/marketing/clients/ClientsList'),
        meta: {
          title: 'Клієнти',
        },
      },
      {
        path: ROUTER_LINKS.CLIENT_CARD + '/:clientId',
        name: 'ClientCard',
        component: () => import('../views/marketing/clients/ClientCard'),
        meta: {
          title: 'Картка клієнта',
        },
      },
      {
        path: ROUTER_LINKS.PROMOTIONS,
        name: 'Promotions',
        component: () => import('../views/marketing/promotions/PromotionsList'),
        meta: {
          title: 'Акції',
        },
      },
      {
        path: ROUTER_LINKS.PROMOTION_CARD + '/:promotionId',
        name: 'PromotionCard',
        component: () => import('../views/marketing/promotions/PromotionCard'),
        meta: {
          title: 'Картка акції компанії',
        },
      },
      {
        path: ROUTER_LINKS.GLOBAL_PROMOTION_CARD + '/:promotionId',
        name: 'GlobalPromotionCard',
        component: () => import('../views/marketing/promotions/PromotionCard'),
        meta: {
          title: 'Картка глобальної акції',
        },
      },
      {
        path: ROUTER_LINKS.STAFF,
        name: 'StaffList',
        component: () => import('../views/administration/staff/StaffList'),
        meta: {
          title: 'Персонал',
        },
      },
      {
        path: ROUTER_LINKS.STAFF_CARD + '/:staffId',
        name: 'StaffCard',
        component: () => import('../views/administration/staff/StaffCard'),
        meta: {
          title: 'Персонал',
        },
      },
      {
        path: ROUTER_LINKS.PARTNERS,
        name: 'PartnersList',
        component: () => import('../views/administration/partners/PartnersList'),
        meta: {
          title: 'Партнери',
        },
      },
      {
        path: ROUTER_LINKS.PARTNER_CARD + '/:partnerId',
        name: 'PartnerCard',
        component: () => import('../views/administration/partners/PartnerCard'),
        meta: {
          title: 'Партнер',
        },
      },
      {
        path: ROUTER_LINKS.TERMINALS,
        name: 'TerminalsList',
        component: () => import('../views/administration/terminals/TerminalsList'),
        meta: {
          title: 'Термінали',
        },
      },
      {
        path: ROUTER_LINKS.TERMINAL_CARD + '/:terminalId',
        name: 'TerminalCard',
        component: () => import('../views/administration/terminals/TerminalCard'),
        meta: {
          title: 'Термінал',
        },
      },
      {
        path: ROUTER_LINKS.DEVICES,
        name: 'DevicesList',
        component: () => import('../views/administration/devices/DevicesList'),
        meta: {
          title: 'Автомати',
        },
      },
      {
        path: ROUTER_LINKS.DEVICE_CARD + '/:deviceId',
        name: 'DeviceCard',
        component: () => import('../views/administration/devices/DeviceCard'),
        meta: {
          title: 'Автомат',
        },
      },
      {
        path: ROUTER_LINKS.SERVICE_POINTS,
        name: 'ServicePointsList',
        component: () => import('../views/administration/service_points/ServicePointsList'),
        meta: {
          title: 'Торгові точки',
        },
      },
      {
        path: ROUTER_LINKS.SERVICE_POINT_CARD + '/:servicePointId',
        name: 'ServicePointCard',
        component: () => import('../views/administration/service_points/ServicePointCard'),
        meta: {
          title: 'Торгова точка',
        },
      },
      {
        path: ROUTER_LINKS.ADMIN_DASHBOARD,
        name: 'AdminDashboard',
        component: () => import('../views/support/AdminDashboard'),
        meta: {
          title: 'Дошка адміністратора',
        },
      },
      {
        path: ROUTER_LINKS.COMPANIES_LIST,
        name: 'CompaniesList',
        component: () => import('../views/admin/CompaniesList'),
        meta: {
          title: 'Компанії',
        },
      },
      {
        path: ROUTER_LINKS.COMPANIES_CARD,
        name: 'CompanyCard',
        component: () => import('../views/administration/company/CompanyCard'),
        meta: {
          title: 'Компанія',
        },
      },
      {
        path: ROUTER_LINKS.SETTINGS,
        name: 'Settings',
        component: () => import('../views/admin/Settings'),
        meta: {
          title: 'Налаштування',
          roles: [ROLE.ADMIN, ROLE.OWNER],
        },
      },
      {
        path: ROUTER_LINKS.DASHBOARD_CONSTRUCTOR_LIST,
        name: 'DashboardConstructorList',
        component: () => import('../views/admin/DashboardConstructorList'),
        meta: {
          title: 'Налаштування',
          roles: [ROLE.ADMIN],
        },
      },
      {
        path: ROUTER_LINKS.USERS,
        name: 'UsersList',
        component: () => import('../views/support/users/UsersList'),
        meta: {
          title: 'Користувачі',
        },
      },
      {
        path: ROUTER_LINKS.USER_CARD + '/:userId',
        name: 'UserCard',
        component: () => import('../views/support/users/UserCard'),
        meta: {
          title: 'Користувач',
        },
      },
      {
        path: ROUTER_LINKS.INFO_CONSTRUCTOR_CATEGORIES,
        name: 'InfoConstructorCategoriesList',
        component: () => import('../views/support/info_constructor/categories_articles/InfoConstructorCategoriesList'),
        meta: {
          title: 'Категорії статтей',
        },
      },
      {
        path: ROUTER_LINKS.INFO_CONSTRUCTOR_ARTICLES_LIST,
        name: 'InfoConstructorArticlesList',
        component: () => import('../views/support/info_constructor/categories_articles/InfoConstructorArticlesList'),
        meta: {
          title: 'Статті',
        },
      },
      {
        path: ROUTER_LINKS.INFO_CONSTRUCTOR_ARTICLES_LIST + '/' + ROUTER_LINKS.INFO_CONSTRUCT_ARTICLE,
        name: 'InfoConstructArticle',
        component: () => import('../views/support/info_constructor/categories_articles/InfoConstructArticle'),
        meta: {
          title: 'Створення статті',
        },
      },
      {
        path: ROUTER_LINKS.INFO_CONSTRUCTOR_ARTICLES_LIST + '/' + ROUTER_LINKS.INFO_ARTICLE,
        name: 'InfoArticleCard',
        component: () => import('../views/support/info_constructor/categories_articles/InfoArticleCard'),
        meta: {
          title: 'Стаття',
        },
      },
      {
        path: ROUTER_LINKS.INFO_CONSTRUCTOR_EVENTS_GROUP_LIST,
        name: 'InfoConstructorEventsGroupList',
        component: () => import('../views/support/info_constructor/events_statuses/InfoConstructorEventsGroupList'),
        meta: {
          title: 'Групи подій',
        },
      },
      {
        path: ROUTER_LINKS.INFO_CONSTRUCTOR_EVENTS_LIST,
        name: 'InfoConstructorEventsListAll',
        component: () => import('../views/support/info_constructor/events_statuses/InfoConstructorEventsList'),
        meta: {
          title: 'Події',
        },
      },
      {
        path: ROUTER_LINKS.INFO_CONSTRUCTOR_EVENTS_LIST + '/:eventTypeId',
        name: 'InfoConstructorEventsList',
        component: () => import('../views/support/info_constructor/events_statuses/InfoConstructorEventsList'),
        meta: {
          title: 'Події',
        },
      },
      {
        path:
          ROUTER_LINKS.INFO_CONSTRUCTOR_EVENTS_LIST + '/:eventTypeId/' + ROUTER_LINKS.INFO_CONSTRUCTOR_EVENT_CARD + '/:eventId',
        name: 'InfoConstructorEventCard',
        component: () => import('../views/support/info_constructor/events_statuses/InfoEventCard'),
        meta: {
          title: 'Подія',
        },
      },
      {
        path: ROUTER_LINKS.INFO_CONSTRUCTOR_EVENTS_LIST + '/:eventTypeId/' + ROUTER_LINKS.INFO_CONSTRUCT_EVENT,
        name: 'InfoConstructEvent',
        component: () => import('../views/support/info_constructor/events_statuses/InfoConstructEvent'),
        meta: {
          title: 'Створення події',
        },
      },
      {
        path: ROUTER_LINKS.DEVICE_EVENTS,
        name: 'DeviceEventsList',
        component: () => import('../views/support/logs/device_events/DeviceEventsList'),
      },
      {
        path: ROUTER_LINKS.PACKAGES,
        name: 'LogPackages',
        component: () => import('../views/support/logs/packages/PackagesList'),
        meta: {
          title: 'Пакети',
        },
      },
      {
        path: ROUTER_LINKS.DEVICE_EVENTS_MAINTENANCE,
        name: 'DeviceEventsListMaintenance',
        component: () => import('../views/maintenance/device_events/DeviceEventsList'),
        meta: {
          title: 'Події',
        },
      },
      {
        path: ROUTER_LINKS.PUSH_NOTIFICATIONS_MAIN,
        name: 'PushNotificationMain',
        component: () => import('../views/support/push_notification/PushNotificationMain'),
        meta: {
          title: 'Пуш-повідомлення',
        },
      },
      {
        path: ROUTER_LINKS.PUSH_NOTIFICATIONS_CARD + '/:notificationId?',
        name: 'PushNotificationSettingCard',
        component: () => import('../views/support/push_notification/PushNotificationSettingCard'),
        meta: {
          title: 'Правило',
        },
      },
      {
        path: ROUTER_LINKS.PUSH_NOTIFICATIONS_SETTINGS + '/:notificationId?',
        name: 'PushNotificationSettings',
        component: () => import('../views/support/push_notification/PushNotificationSettings'),
        meta: {
          title: 'Налаштування пуш-повідомлень',
        },
      },
      {
        path: ROUTER_LINKS.PUSH_NOTIFICATIONS_DELIVERY + '/:deliveryId',
        name: 'PushNotificationDelivery',
        component: () => import('../views/support/push_notification/PushNotificationDelivery'),
        meta: {
          title: 'Розсилка пуш-повідомлень',
        },
      },
      {
        path: ROUTER_LINKS.FIRMWARES,
        name: 'FirmwaresList',
        component: () => import('../views/support/firmwares/FirmwaresList'),
        meta: {
          title: 'Прошивки',
        },
      },
      {
        path: ROUTER_LINKS.FIRMWARE_CARD + '/:firmwareId',
        name: 'FirmwareCard',
        component: () => import('../views/support/firmwares/FirmwareCard'),
        meta: {
          title: 'Картка ПЗ',
        },
      },
    ],
  },
  /* TODO: add 404 page */
  // otherwise redirect to home
  {
    path: '*',
    redirect: { name: 'Home' },
  },
  /*{
    path: '*',
    name: 'NotFound',
    component: NotFound,
    meta: {
      title: '404 Not Found',
    },
  },*/
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL + 'spa/',
  routes,
})

router.beforeEach(async (to, from, next) => {
  const { name, path, matched } = to
  const currentUserRoles = store.state.profile.userRoles
  const companyLocked = !!(store.state.companies.currentCompany && store.state.companies.currentCompany.status === 2)

  if (from.name === 'CompanyLocked' && companyLocked) return
  if (name === 'Login' || name === 'CompanyLocked') return next()
  if (companyLocked) return next({ name: 'CompanyLocked' })
  if (path === '/') {
    next({ name: !companyLocked ? 'Home' : 'CompanyLocked' })
  } else {
    const requiresAuth = matched.some((record) => record.meta.requiresAuth)

    if (!isAuthenticated() && !['Login', 'Registration', 'Verification', 'RestorePassword'].includes(from.name)) {
      return next({
        name: 'Login',
        params: { errorMessage: 'Будь ласка, авторизуйтеся!' },
      })
    }
    if (requiresAuth && isAuthenticated()) {
      if (isTokenExpired()) {
        const refreshToken = getLocalRefreshToken()
        if (refreshToken) {
          const payload = { refresh_token: refreshToken }
          const api = new AxiosApi()
          try {
            const res = await api.post('/api/v1/auth/refresh-token', payload)
            const { access_token, refresh_token } = res.data[0].data
            window.localStorage.setItem('accessToken', access_token)
            window.localStorage.setItem('refreshToken', refresh_token)
          } catch (e) {
            next({
              name: 'Login',
              params: { errorMessage: 'Не вдалося оновити ваш ключ доступу. Будь ласка, авторизуйтеся заново!' },
            })
          }
        } else {
          next({
            name: 'Login',
            params: { errorMessage: 'Не вдалося оновити ваш ключ доступу. Будь ласка, авторизуйтеся заново!' },
          })
        }
      }
      const authorized = matched.every((record) => {
        if (record.meta.roles) {
          return record.meta.roles.some((role) => currentUserRoles.includes(role))
        } else {
          return true
        }
      })
      if (authorized) {
        next()
      } else {
        next({ name: '403' })
      }
    } else {
      next()
    }
  }
})

export default router
